@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: "almoni-demibold";
  font-style: normal;
  src: url("./assets/fonts/almoni-tzar-demibold-aaa.woff2") format("woff2");
}

@font-face {
  font-family: "almoni-light";
  font-style: normal;
  src: url("./assets/fonts/almoni-tzar-light-aaa.woff2") format("woff");
}

:root {
  --primary-bg: #FFF;
  --foundation-violet: #1E0F3C;
  --foundation-btn-color: #E9E7EC;
  --text-primary: #1E0F3C;
  --text-black: #000;
  --placeholder-color: #000;
  --roboto: "Roboto", sans-serif;
  --assistant: "Assistant", sans-serif;
}

body {
  font-family: almoni-light, sans-serif !important;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  font-weight: 400;
  overflow-x: hidden;
  color: var(--assistant) !important;
  font-family: 'Assistant', sans-serif;
  font-family: 'Roboto', sans-serif;
}

.container{
  max-width: 1440px !important;
  padding: 0px 64px !important;
}
.black-btn {
  padding: 12px 40px !important;
  border-radius: 4px !important;
  background: var(--foundation-violet) !important;
  color: var(--foundation-btn-color) !important;
  font-family: var(--assistant) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  height: 48px;
  width: 100%;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
}
.border-btn {
  background: transparent !important;
  border-radius: 4px !important;
  border: 2px solid var(--foundation-violet) !important;
  padding: 16px !important;
  width: 100%;
  height: 48px;
  color: var(--foundation-violet) !important;
  font-family: var(--assistant) !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 150% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}
.pink-btn {
  border-radius: 4px !important;
  background: #FFD7C8 !important;
  color: #B76E54 !important;
  font-family: var(--assistant) !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 150% !important;
  border: none !important;
  max-width: 136px;
  width: 100%;
  height: 36px;
}
.rtl {
  text-align: right;
}
.whats-app-icon {
  background: transparent;
  border-radius: 100%;
  bottom: 40px;
  right: 15px;
  box-shadow: 0 19px 30px -5px rgba(232,0,84,.25);
  color: #fff;
  cursor: pointer;
  position: fixed;
  text-align: center;
  transition: all .5s;
  width: 75px;
  height: 75px;
}
body.show-enable-toolbar .whats-app-icon {
  bottom: 250px;
  transition: all .5s;
}
.whats-app-icon a img {
  width: 100%;
}

/* for mobile */
@media screen and (max-width: 991px) { 
  .container {
    padding: 0px 24px !important;
  }
  .whats-app-icon {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 767px) {
  .whats-app-icon {
    bottom: 45px;
    width: 50px;
    height: 50px;
  }
  body.show-enable-toolbar .whats-app-icon {
    bottom: 110px; 
  }
}